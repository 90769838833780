.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    width: 100%;
    max-width: 400px;
  }
  
  .logo {
    width: 200px;
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
  }
  
  button {
    width: 65%;
    padding: 10px;
    margin-top: 10px;
    background-color: #3897f0;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-family :system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }
  
  button:hover {
    background-color: #1877c8;
  }
  

.login_page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_container {
    width: 370px;
    min-height: 80vh;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.main_cont {
        border: 1px solid #dbdbdb;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* min-height: 50vh; */
        width: 100%;
}
.logo {
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo img {
    width: 200px;
}
.login_form {
    flex-basis: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}
.login_form input {
    width: 60%;
    outline: none;
    padding: 10px;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #f2f0f074;
}

.forgot{
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(11, 11, 60);
    margin-top: 10px;
    font-size: 11px;
    margin-bottom: 17px;
}

.sign_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.create {
    border: 1px solid #dbdbdb; 
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:  Tahoma, Geneva, Verdana, sans-serif;
    
    font-weight: 500;
    color: rgb(66, 65, 65);
    font-size: 14px;
}
.create a {
    margin-left: 5px;
    color: #1a8af3;
    text-decoration: none;
    font-weight: 600;
}

.main_cont h5 {
    font-size: 12px;
    margin: 0;
    margin-top: 8px;
    font-family: sans-serif;
    font-weight: 300;
    color:rgb(115, 112, 112);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content : space-between;
    width: 90%;
}
.main_cont h5 hr {
    width: 40%;
    height: 0.3px;
}
.fb_span {
    font-family :system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin: 10px auto;
    margin-bottom: 7px;
    color: #4267B2;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.fb_span i {
    font-size: 17px;
    margin-right: 5px;
}
.get_app {
    width: 100%;
}
.get_app h2 {
    font-weight: 300;
    font-family: sans-serif;
    font-size: 16px;
    margin: 10px;
    text-align: center;
}
.get_app div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.get_app div img {
    flex-basis: 40%;
    width: 250px;
    margin: 10px 15px;
}


@media (max-width : 400px) {
    .login_container {
        width: 95%;
        margin: 10px auto;
    }
    .login_form input {
        width: 80%;
    }
    button {
        width: 85%;
    }
}