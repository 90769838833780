* {
padding: 0;
margin: 0;
box-sizing: border-box;

}
.header {
    width: 100%;
    height: 70px;
    overflow: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

}
.logo_nav {
    flex-basis: 50%;
    display: flex;
    align-items: flex-start;

}
.logo_nav img {
    mix-blend-mode: darken;
}
.right_nav {
    flex-basis: 50%;
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    height: 100%;
}
.header_btn {
    padding: 10px 30px;
    margin-top: 10px;
    background-color: #3897f0;
    text-decoration: none;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-family :system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Home page  */
.bg_main {
    background: url('../image/log.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}
.home_page{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(135deg, #e4849b7e, #ffefa069 , rgba(158, 255, 194, 0.446)c1);
}

.content {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    -webkit-transform: translate(-50% , -50%);
    -moz-transform: translate(-50% , -50%);
    -ms-transform: translate(-50% , -50%);
    -o-transform: translate(-50% , -50%);
}
@media (max-width : 550px) {
    .content {
        width: 90%;
        margin: auto;
    }
}
.content h1 {
   font-size: 40px;
   color: white;
   letter-spacing: 2px;
   margin: 10px auto;
}
.content p {
    font-size: 11px;
    letter-spacing: 0.7px;
    font-weight: 40;
    color: white;
}